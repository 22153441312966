import { useCallback, useContext } from 'react'
import fetch from 'isomorphic-unfetch'

import { UserContext } from '../controllers'
import { globalSettings } from '../settings'
import useFetch from './useFetch'

const { webBaseUrl } = globalSettings
const facebookClientId = process.env.NEXT_PUBLIC_WEB_AUTH_PROVIDERS_FACEBOOK_CLIENT_ID
const appleClientId = process.env.NEXT_PUBLIC_WEB_AUTH_PROVIDERS_APPLE_CLIENT_ID

const getVerySpecialAppleOnlyUrl = () => {
    // Apple doesn't allow localhost or http, so this is ok for production, but also needed for tools like ngrok (because webRoot is hardcoded)
    if (typeof window === 'undefined') {
        return null
    }

    return `https://${window.location.hostname}/api/apple/auth`
}

const useUser = () => {
    const user = useContext(UserContext)

    const { actions: fetchAlt } = useFetch()

    // NOTE: Same for all users
    const taxonId = '4295a5e0-610d-11eb-85d8-0242ac120010'

    const createNewUserWithCredentials = useCallback(async registerPayload => {
        const response = await fetch(`/api/podravkaio/auth/create-user`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registerPayload)
        })

        const userData = await response.json()

        const accessToken = userData?.access_token

        const userWithData = await fetch(`${process.env.NEXT_PUBLIC_PODRAVKAIO_ROOT}/users/me`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': ''
            }
        }).then(userResponse => userResponse.json())

        // NOTE: setting gamify "ON" on registration
        // TODO: separate into a hook
        const gamifySet = async (endpoint, payload) =>
            fetch(
                `${process.env.NEXT_PUBLIC_PODRAVKAIO_ROOT}/users/${userWithData?.id}/${endpoint}`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Accept-Language': 'hr',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(payload)
                },
                []
            )

        const gamifySetForNewUser = async () =>
            fetchAlt.create({
                data: {
                    endpoint: '/gamification/new-user',
                    payload: {
                        uuid: userWithData?.id
                    }
                }
            })

        await Promise.all([
            gamifySet('taxons/coolinarika-flags', {
                taxon: `/api/taxonomies/protected-coolinarika-flags/taxons/${taxonId}`
            }),
            gamifySetForNewUser()
        ])

        return userData
    }, [])

    const createFacebookRegisterUrl = useCallback(currentPathname => {
        // const facebookLoginUrl = 'https://www.facebook.com/v16.0/dialog/oauth'

        const facebookRegisterPayload = {
            client_id: facebookClientId,
            scope: 'email',
            response_type: 'code',
            auth_type: 'rerequest',
            display: 'popup',
            redirect_uri: `${webBaseUrl}/${currentPathname && currentPathname !== '/' ? currentPathname : ''}`
        }

        const facebookLoginUrl = new URL('https://www.facebook.com/v16.0/dialog/oauth')

        Object.entries(facebookRegisterPayload).forEach(([key, value]) =>
            facebookLoginUrl.searchParams.append(key, value)
        )

        return facebookLoginUrl.href
    }, [])

    const createAppleRegisterUrl = useCallback(currentPathname => {
        const appleLoginUrl = 'https://appleid.apple.com/auth/authorize'

        const appleRegisterPayload = {
            client_id: appleClientId,
            // TODO: redirect to pathname when (if possible) we add wildcard urls to apple
            redirect_uri: getVerySpecialAppleOnlyUrl(),
            scope: ['name', 'email'].join(' '),
            response_type: ['code', 'id_token'].join(' '),
            response_mode: 'form_post',
            auth_type: 'rerequest',
            display: 'popup'
        }

        const appleLoginParams = Object.entries(appleRegisterPayload)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&')

        const appleLoginWithParamsUrl = `${appleLoginUrl}?${appleLoginParams}`

        return appleLoginWithParamsUrl
    }, [])

    const getFacebookProfileData = useCallback(async ({ code, currentPathname, token }) => {
        if (token) {
            const response = await fetch('https://graph.facebook.com/me?fields=email,name,picture', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            const userData = await response.json()

            return userData
        }

        const response = await fetch(`/api/facebook/auth`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code, currentPathname })
        })
        const userData = await response.json()

        return userData
    }, [])

    const requestResetPasswordEmail = useCallback(async ({ email }) => {
        const userCredentials = {
            email
        }

        const response = await fetch(`/api/podravkaio/auth/password-reset/token/request`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userCredentials)
        })

        const userData = await response.json()

        return userData
    }, [])

    const validatePasswordResetToken = useCallback(async ({ token }) => {
        const response = await fetch(`/api/podravkaio/auth/password-reset/token/validate`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        })

        const tokenData = await response.json()

        return tokenData
    }, [])

    const newPassword = useCallback(async ({ password, token }) => {
        const newPasswordPayload = {
            password,
            token
        }

        const response = await fetch(`/api/podravkaio/auth/password-reset/change-password`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newPasswordPayload)
        })

        const passwordData = await response.json()

        return passwordData
    }, [])

    const validateCurrentPassword = useCallback(async ({ email, username, password }) => {
        const newPasswordPayload = {
            email,
            username,
            password
        }

        const response = await fetch(`/api/podravkaio/auth/password-change/validate`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newPasswordPayload)
        })

        const passwordData = await response.json()

        return passwordData
    }, [])

    const changePassword = useCallback(async ({ password, email }) => {
        const changePasswordPayload = {
            password,
            email
        }

        const response = await fetch(`/api/podravkaio/auth/password-change/change`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(changePasswordPayload)
        })

        const passwordData = await response.json()

        return passwordData
    }, [])

    const validateUsername = useCallback(async username => {
        const response = await fetch(`/api/podravkaio/auth/username`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        })

        const usernameData = await response.json()

        return usernameData
    }, [])

    return {
        ...user,
        createNewUserWithCredentials,
        requestResetPasswordEmail,
        validatePasswordResetToken,
        newPassword,
        changePassword,
        createFacebookRegisterUrl,
        createAppleRegisterUrl,
        getFacebookProfileData,
        validateUsername,
        validateCurrentPassword
    }
}

export default useUser
