import { useCallback, useEffect, useMemo } from 'react'
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux'

import { FiltersActions, SearchActions } from '../actions'
import { globalSettings } from '../settings'
import { useAnalytics } from './useAnalytics'
import useBreakpoints, { lg } from './useBreakpoints'
import { useListElastic } from './useElastic'
import useQueryState from './useQueryState'
import useUser from './useUser'

const { apiBaseUrl } = globalSettings

// TODO: Remove this feature after Search optimisation
// SUGGESTIONS_ENABLED - feature flag set in env, if it is set to 'true' it will fetch results from elastic
const suggestionsEnabled = process.env.NEXT_PUBLIC_SUGGESTIONS_ENABLED?.toLowerCase() === 'true'

const setSearchSuggestionsQuery = (value = '') => ({
    _source: 'suggestion',

    query: {
        script_score: {
            query: {
                bool: {
                    must: [
                        {
                            multi_match: {
                                query: value,
                                type: 'bool_prefix',
                                fields: ['suggestion', 'suggestion._2gram', 'suggestion._3gram']
                            }
                        }
                    ]
                }
            },
            min_score: 0,
            script: {
                // eslint-disable-next-line max-len, @typescript-eslint/quotes
                source: `_score * (doc['weight'].size() == 0 ? 1 : Math.pow(doc['weight'].value, 0.1))`
            }
        }
    }
})

const suggestionsSelector = ({ search, filters }) => ({
    isSearchOpen: search.isOpen,
    isFiltersOpen: filters.isOpen
})

const useSearchSuggestions = () => {
    const dispatch = useDispatch()
    const [isDesktopView] = useBreakpoints(lg)
    const { isSearchOpen, isFiltersOpen } = useSelector(suggestionsSelector, shallowEqual)
    const suggestionsSearchUrl = `${apiBaseUrl}/elastic/proxy/coolinarika-search-suggestions/_search`

    const getSearchSuggestions = useCallback(
        suggestionsEnabled
            ? async searchValue => {
                  if (searchValue) {
                      try {
                          const body = `${JSON.stringify(setSearchSuggestionsQuery(searchValue))}\n`
                          const response = await fetch(suggestionsSearchUrl, {
                              method: 'POST',
                              body,
                              headers: {
                                  'content-type': 'application/x-ndjson'
                              }
                          })

                          const { hits } = await response.json()

                          if (!Array.isArray(hits?.hits)) {
                              return []
                          }
                          const unnestedHits = hits.hits.map(({ _source }) => ({
                              value: _source?.suggestion,
                              label: _source?.suggestion
                          }))

                          return unnestedHits
                      } catch (error) {
                          // eslint-disable-next-line no-console
                          console.error('SEARCH TERM ERROR', error)

                          return []
                      }
                  } else {
                      return []
                  }
              }
            : () => null,
        [suggestionsSearchUrl]
    )

    const handleInputFocus = useCallback(() => {
        if (!isFiltersOpen && isDesktopView) {
            dispatch(FiltersActions.open())
        }
        if (!isSearchOpen) {
            dispatch(SearchActions.open())
        }
    }, [isFiltersOpen, isDesktopView, isSearchOpen, dispatch])

    const handleToggleFilters = useCallback(() => {
        dispatch(FiltersActions.toggle())
    }, [dispatch])

    return {
        getSearchSuggestions,
        handleInputFocus,
        handleToggleFilters,
        isSearchOpen,
        isFiltersOpen
    }
}
const transformResponseData = responseData => ({
    pageParams: responseData?.pageParams || [],
    aggregations: responseData?.pages?.[0]?.aggregations || {},
    pages: responseData?.pages?.map(page => ({ data: page.data })),
    total: responseData?.pages?.[0]?.total || 0
})

const searchSelector = ({ search }) => ({
    isSearchOpen: search.isOpen,
    isSearchManuallyClosed: search.isManuallyClosed
})

const getStringifiedSearchParams = searchParams => {
    const urlSearchParams = new URLSearchParams(searchParams)

    if (urlSearchParams.has('pojam')) {
        urlSearchParams.set('pretrazivanje', urlSearchParams.get('pojam'))
        urlSearchParams.delete('pojam')
    }

    const stringifiedSearchParams = urlSearchParams.toString()

    return stringifiedSearchParams
}

const useSearch = () => {
    const dispatch = useDispatch()
    const { isSearchOpen, isSearchManuallyClosed } = useSelector(searchSelector, shallowEqual)
    const [{ pretrazivanje }, setActiveFilters] = useQueryState({})
    const { profile: userData, isLoggedIn } = useUser()
    const { eventWithTrackingData } = useAnalytics('coolinarika.filter', {
        trackingData: {
            elementCategory: 'search',
            elementLabel: 'Filter',
            elementPageLocation: 'body'
        }
    })

    const [searchParams, activeFiltersLength] = useMemo(() => {
        const activeFilters = pretrazivanje ? JSON.parse(pretrazivanje) : {}
        const length = Object.entries(activeFilters).reduce((acc, [_key, value]) => {
            if (Array.isArray(value)) {
                return acc + value.length
            }
            return acc + 1
        }, 0)

        return [activeFilters, length]
    }, [pretrazivanje])

    const {
        data,
        pages,
        ref: searchRef,
        total: resultsLength,
        refetch,
        isFetched,
        status
    } = useListElastic({
        resource: 'search',
        settings: {
            select: transformResponseData,
            cacheTime: 600000 // 10 min
        },
        params: {
            query: {
                search: searchParams?.pojam,
                byCoolinarika: !!searchParams['by-coolinarika'],
                hasVideo: !!searchParams['imaju-video'],
                userId: isLoggedIn && !!userData?.id ? userData?.id : undefined,
                savedByUser: !!searchParams['moji-spremljeni'],
                skillLevel: searchParams?.['tezina-pripreme'],
                mealType: searchParams?.['grupa-jela'],
                preparationType: searchParams?.['nacin-pripreme']
            }
        },
        enabled: !!Object.keys(searchParams).length // searchParams
    })

    useEffect(() => {
        if (!pages?.length && !Object.keys(searchParams).length) {
            refetch()
        }
    }, [pages?.length, searchParams, isLoggedIn, userData])

    const searchResults = useMemo(() => pages.reduce((acc, page) => [...acc, ...(page?.data || [])], []), [pages])

    const filterData = useMemo(() => {
        if (!data?.aggregations) {
            return {}
        }

        return data.aggregations
    }, [data?.aggregations])

    const handleCloseSearchManually = useCallback(() => {
        const params = getStringifiedSearchParams(searchParams)
        window.localStorage.setItem(
            'search-scroll',
            JSON.stringify({
                [params]: document.getElementById('search-results-wrapper')?.scrollTop
            })
        )
        batch(() => {
            dispatch(SearchActions.close())
            dispatch(SearchActions.manuallyClosed(true))
            dispatch(FiltersActions.close())
        })
    }, [dispatch, searchParams])

    // TODO: This is a really ugly workaround to fix scroll
    // restoration on Firefox. It should probably be refactored to redux.
    const handleClose = useCallback(() => {
        const params = getStringifiedSearchParams(searchParams)
        window.localStorage.setItem(
            'search-scroll',
            JSON.stringify({
                [params]: document.getElementById('search-results-wrapper')?.scrollTop
            })
        )
        setTimeout(() => {
            batch(() => {
                dispatch(SearchActions.manuallyClosed(false))
                dispatch(FiltersActions.close())
            })
        }, 500)
    }, [dispatch, searchParams])

    useEffect(() => {
        if (isSearchOpen) {
            const savedScroll = window.localStorage.getItem('search-scroll')
            const savedScrollJson = savedScroll && JSON.parse(savedScroll)
            const savedParams = savedScrollJson && Object.keys(savedScrollJson)?.[0]

            const currentParams = getStringifiedSearchParams(searchParams)

            const wrapper = document.getElementById('search-results-wrapper')

            if (wrapper) {
                setTimeout(() => {
                    if (savedParams === currentParams) {
                        wrapper.scrollTop = savedScrollJson[savedParams]
                    } else {
                        wrapper.scrollTop = 0
                    }
                }, 120)
            }
        }
    }, [isSearchOpen, searchParams])

    useEffect(() => {
        if (isSearchOpen && isFetched && status === 'success') {
            eventWithTrackingData({
                filter: {
                    ...(searchParams?.pojam && {
                        searchQuery: searchParams?.pojam
                    }),
                    ...(searchParams?.['grupa-jela'] && {
                        foodCategory: searchParams?.['grupa-jela']
                    }),
                    ...(searchParams?.['nacin-pripreme'] && {
                        foodPreparation: searchParams?.['nacin-pripreme']
                    }),
                    ...(searchParams?.['tezina-pripreme'] && {
                        complexity: searchParams?.['tezina-pripreme']
                    })
                }
            })
        }
    }, [isSearchOpen, isFetched, status, searchParams])

    const handleOpenSearch = useCallback(() => {
        dispatch(SearchActions.open())
    }, [dispatch])

    const handleToggleButtonListChange = useCallback(
        event => {
            const { name, checked } = event.target

            setActiveFilters(current => {
                const globalParams = { ...current }
                const searchFilters = globalParams?.pretrazivanje ? JSON.parse(globalParams.pretrazivanje) : {}

                if (searchFilters[name]) {
                    delete searchFilters[name]

                    if (!Object.keys(searchFilters).length) {
                        delete globalParams.pretrazivanje

                        return globalParams
                    }

                    return {
                        ...current,
                        pretrazivanje: JSON.stringify(searchFilters)
                    }
                }

                return {
                    ...current,
                    pretrazivanje: JSON.stringify({
                        ...searchFilters,
                        [name]: checked
                    })
                }
            })
        },
        [setActiveFilters]
    )

    const handleMultilistChange = useCallback(
        event => {
            if (!event) {
                setTimeout(() => {
                    setActiveFilters(current => {
                        const globalParams = { ...current }
                        const searchFilters = globalParams?.pretrazivanje ? JSON.parse(globalParams.pretrazivanje) : {}

                        Object.keys(searchFilters).forEach(key => {
                            if (key !== 'pojam') {
                                delete searchFilters[key]
                            }
                        })

                        return {
                            ...globalParams,
                            pretrazivanje: JSON.stringify({
                                ...searchFilters
                            })
                        }
                    })
                }, 1500)
                return
            }

            const { name, value } = event.target

            setActiveFilters(current => {
                const globalParams = { ...current }
                const searchFilters = globalParams?.pretrazivanje ? JSON.parse(globalParams.pretrazivanje) : {}
                const rangeArray = searchFilters[name] || []

                if (!rangeArray) {
                    const newRangeArray = [value]

                    return {
                        ...globalParams,
                        pretrazivanje: JSON.stringify({
                            ...searchFilters,
                            [name]: newRangeArray
                        })
                    }
                }

                if (rangeArray?.includes(value)) {
                    const filteredRangeArray = rangeArray.filter(item => item !== value)

                    if (!filteredRangeArray.length) {
                        delete searchFilters[name]

                        if (!Object.keys(searchFilters).length) {
                            delete globalParams.pretrazivanje

                            return globalParams
                        }

                        return {
                            ...globalParams,
                            pretrazivanje: JSON.stringify({
                                ...searchFilters
                            })
                        }
                    }

                    return {
                        ...globalParams,
                        pretrazivanje: JSON.stringify({
                            ...searchFilters,
                            [name]: filteredRangeArray
                        })
                    }
                }

                const updatedRangeArray = [...rangeArray, value]

                return {
                    ...globalParams,
                    pretrazivanje: JSON.stringify({
                        ...searchFilters,
                        [name]: updatedRangeArray
                    })
                }
            })
        },
        [setActiveFilters]
    )

    const handleSelectChange = useCallback(
        (value, option) => {
            const selectedValue = value?.value
            const name = option?.name || 'pojam'
            setActiveFilters(current => {
                const globalParams = { ...current }
                const searchFilters = globalParams?.pretrazivanje ? JSON.parse(globalParams.pretrazivanje) : {}

                if (!selectedValue && !!searchFilters[name]) {
                    delete searchFilters[name]

                    if (!Object.keys(searchFilters).length) {
                        delete globalParams.pretrazivanje

                        return globalParams
                    }

                    return {
                        ...current,
                        pretrazivanje: JSON.stringify(searchFilters)
                    }
                }

                return {
                    ...current,
                    pretrazivanje: JSON.stringify({
                        ...searchFilters,
                        [name]: selectedValue
                    })
                }
            })
        },
        [setActiveFilters]
    )

    return {
        filterData,
        searchRef,
        searchResults,
        activeFilters: searchParams,
        activeFiltersLength,
        resultsLength,
        handleCloseSearchManually,
        handleClose,
        handleOpenSearch,
        handleToggleButtonListChange,
        handleMultilistChange,
        handleSelectChange,
        isSearchOpen,
        isSearchManuallyClosed,
        showResultsPanel: !!activeFiltersLength,
        showNoResults: isFetched && status === 'success' && !searchResults?.length && !!activeFiltersLength
    }
}

export { useSearch, useSearchSuggestions }
