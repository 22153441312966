import { useCallback, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Media } from '@hmn/coolinarika-web-core/controllers/MediaContext'
import { useSearchSuggestions } from '@hmn/coolinarika-web-core/hooks'

import { debounce } from '@hmn/coolinarika-web-ui/helpers/utils/debounce'

import { Button, buttonVariants } from '../../../../Button'
import { ArrowSmallIcon, CloseIcon, FiltersIcon, Icon } from '../../../../Icon'
import Input from './Input.component'
import styles from './SearchInput.style'

const suggestionsEnabled = process.env.NEXT_PUBLIC_SUGGESTIONS_ENABLED?.toLowerCase() === 'true'

const getLabelAndValue = ({ suggestion, label, value }) => suggestion || label || value

const noOptionsMessage = () => 'Nema rezultata'

const loadingMessage = () => 'Učitavanje...'

const formatCreateLabel = value => `Pretraži "${value}"`

const getNewOptionData = (value, label) => ({ value, label })

const SearchInputStyled = styled.div(props => ({ ...styles(props) }))

const SearchInput = ({
    value,
    inputValue,
    className,
    selectedFiltersCount,
    componentId,
    handleCloseSearch,
    onChange,
    onInputChange
}) => {
    const searchRef = useRef()
    const { isSearchOpen, isFiltersOpen, getSearchSuggestions, handleInputFocus, handleToggleFilters } =
        useSearchSuggestions()

    const getDebouncedSuggestions = useCallback(
        debounce((inputText, callback) => {
            getSearchSuggestions(inputText).then(options => callback(options))
        }, 300),
        []
    )

    const inputComponentProps = useMemo(() => {
        const inputProps = {
            name: componentId,
            placeholder: 'Što ti se danas jede?',
            id: 'globalSearch',
            instanceId: 'globalSearch',
            backspaceRemovesValue: true,
            isClearable: true,
            isSearchable: true,
            openMenuOnClick: false,
            openMenuOnFocus: false,
            allowCreateWhileLoading: true,
            createOptionPosition: 'first',
            controlShouldRenderValue: false,
            value,
            inputValue,
            onChange,
            onInputChange,
            onFocus: handleInputFocus,
            formatCreateLabel,
            noOptionsMessage,
            loadingMessage,
            getNewOptionData,
            loadOptions: getDebouncedSuggestions,
            getOptionLabel: getLabelAndValue,
            getOptionValue: getLabelAndValue
        }

        return suggestionsEnabled
            ? inputProps
            : {
                  ...inputProps,
                  components: {
                      LoadingIndicator: () => null
                  }
              }
    }, [getSearchSuggestions, onChange, handleInputFocus, componentId])

    return (
        <SearchInputStyled className={className}>
            <Media lessThan="lg">
                <Button
                    className="searchFiltersBtn"
                    variant={buttonVariants.SECONDARY}
                    onClick={handleToggleFilters}
                    disableBorder
                    title={isFiltersOpen ? 'Zatvori filtere' : 'Otvori filtere'}
                    isIconOnly>
                    {isFiltersOpen ? <Icon icon={ArrowSmallIcon} /> : <Icon icon={FiltersIcon} />}
                    {!!selectedFiltersCount && <span className="searchFiltersBtn_count">{selectedFiltersCount}</span>}
                </Button>
            </Media>

            <Input ref={searchRef} className="searchInput" {...inputComponentProps} />

            {isSearchOpen && (
                <Button
                    className="searchCloseBtn"
                    variant={buttonVariants.TERTIARY}
                    onClick={handleCloseSearch}
                    disableBorder
                    title="Zatvori pretragu"
                    isIconOnly>
                    <Icon icon={CloseIcon} />
                </Button>
            )}
        </SearchInputStyled>
    )
}

const searchInputVariants = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
})

SearchInput.propTypes = {
    value: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    inputValue: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(searchInputVariants)]),
    selectedFiltersCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    componentId: PropTypes.string,
    handleCloseSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired
}

SearchInput.defaultProps = {
    value: {},
    inputValue: '',
    className: undefined,
    variant: searchInputVariants.PRIMARY,
    selectedFiltersCount: undefined,
    componentId: 'pojam'
}

export { searchInputVariants }

export default SearchInput
