/**
 * Debounce - custom debounce function
 * @param {function} func
 * @param {number} delay
 * @returns
 */
export function debounce(func, delay = 300) {
    if (typeof func !== 'function') {
        throw Error('Unexpected argument: must be function.')
    }

    if (typeof delay !== 'number') {
        throw Error('Delay must be integer number')
    }

    let timeout

    return (...args) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            func(...args)
        }, delay)
    }
}
